<template>
    <div class="scroll-container">
        <div class="menu-list-item font-indie">
            <icon class="menu-list-icon" :img="icon" :size="size"></icon>
            <h1>{{ h1 }}</h1>
        </div>

        <div class="content-view font-qs">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ContentView",
        props: {
            icon: {
                type: String,
                default: 'menu-icon'
            },
            h1: {
                type: String,
                default: ''
            }
        },
        computed: {
            size() {
                return this.$bus.mobileView ? 38 : 48
            }
        }
    }
</script>

<style scoped>

</style>