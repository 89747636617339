<template>
    <div>
        <div id="container">
            <div v-if="$bus.mobileView" class="left-white-banner">
                <div class="menu-icon" @click="action"><icon :img="icon"></icon></div>
            </div>
            <p v-if="$bus.mobileView" class="container-logo text-center"><img src="/images/logo-white-home.png" /></p>


            <div v-if="!$bus.mobileView" id="header-desktop">
                <div id="desktop-logo">
                    <router-link :to="{name: 'home'}">
                        <img src="/images/logo-blue-gradient.png" />
                    </router-link>
                </div>
                <div id="desktop-menu" class="font-qs">
                    <h1>Creation de solution web & digital</h1>
                    <hr>
                    <div class="list-icons">
                        <div @click="linkTo('who_is')" class="menu-desktop-item font-indie">
                            <icon class="menu-desktop-icon" img="who_is" size="48"></icon>
                            <div class="icon-title">
                                <img class="arrow" src="/images/arrow.png" />
                                <span>Qui Sommes-nous ?</span>
                            </div>
                            <hr v-if="$route.name === 'who_is'">
                        </div>
                        <div @click="linkTo('services')" class="menu-desktop-item font-indie">
                            <icon class="menu-desktop-icon" img="services" size="48"></icon>
                            <div class="icon-title">
                                <img class="arrow" src="/images/arrow.png" />
                                <span>Services</span>
                            </div>
                            <hr v-if="$route.name === 'services'">
                        </div>
                        <div @click="linkTo('realisations')" class="menu-desktop-item font-indie">
                            <icon class="menu-desktop-icon" img="realisations" size="48"></icon>
                            <div class="icon-title">
                                <img class="arrow" src="/images/arrow.png" />
                                <span>Réalisations</span>
                            </div>
                            <hr v-if="$route.name === 'realisations'">
                        </div>
                        <div @click="linkTo('contact')" class="menu-desktop-item font-indie">
                            <icon class="menu-desktop-icon" img="contact" size="48"></icon>
                            <div class="icon-title">
                                <img class="arrow" src="/images/arrow.png" />
                                <span>Contact</span>
                            </div>
                            <hr v-if="$route.name === 'contact'">
                        </div>
                        <div id="blog-icon" @click="linkTo('blog')" class="menu-desktop-item font-indie">
                            <icon class="menu-desktop-icon" img="blog" size="48"></icon>
                            <div class="icon-title">
                                <img class="arrow" src="/images/arrow.png" />
                                <span>Blog</span>
                            </div>
                            <hr v-if="$route.name === 'blog'">
                        </div>
                    </div>
                </div>
            </div>


            <slot></slot>
        </div>

        <slot name="slider"></slot>

        <slot name="copyright">
            <div id="copyright" class="on-left">© {{ $bus.currentYear }} by Stephane PECQUEUR</div>
        </slot>
    </div>
</template>

<script>
    export default {
        name: "Container",
        props: {
            icon: {
                type: String,
                default: 'menu-icon'
            },
            action: {
                type: Function,
                default: () => {

                }
            }
        },
        methods: {
            linkTo(target) {
                this.$router.push({ name: target })
            }
        }
    }
</script>

<style scoped>

</style>