// Check localStorage compatibility
if (!window.localStorage) {
    alert('your browser does not seem to be compatible with some features of the application, please update it.')
}

// log Environment variables
console.debug('environment variables', process.env)

/**************** IMPORT PLUGINS *******************/

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    // Import Popper (For Bootstrap Dropdown)
    window.Popper = require('popper.js').default;
    // Import Jquery
    window.$ = window.jQuery = require('jquery');

    require('bootstrap');
} catch (e) {}

// Import VueJs
window.Vue = require('vue');

// Import Bus for globals data
import Bus from './plugins/bus/index'
import BusConfig from './busConfig'
Vue.use(Bus, BusConfig)

import Vue2TouchEvents from 'vue2-touch-events'
Vue.use(Vue2TouchEvents)
