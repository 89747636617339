import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
    base: '/',
    mode: 'history',
    routes: [
        { name: 'home', path: '/', component: () => import(/* webpackChunkName: "js/chunks/Home" */ './components/Home') },
        { name: 'who_is', path: '/who-are-we', component: () => import(/* webpackChunkName: "js/chunks/WhoIs" */ './components/WhoIs') },
        { name: 'services', path: '/services', component: () => import(/* webpackChunkName: "js/chunks/Services" */ './components/Services') },
        { name: 'realisations', path: '/realisations', component: () => import(/* webpackChunkName: "js/chunks/Realisations" */ './components/Realisations') },
        { name: 'contact', path: '/contact', component: () => import(/* webpackChunkName: "js/chunks/Contact" */ './components/Contact') },
        { name: 'blog', path: '/blog', component: () => import(/* webpackChunkName: "js/chunks/Blog" */ './components/Blog') },

        { path: '*', redirect: to => { return '/' } } // Not found

    ]
})

export default router
