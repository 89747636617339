
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

// Import Routes
import router from './router'

//import Layout from './components/Layout'
const Layout = () => import(/* webpackChunkName: "js/chunks/Layout" */ './components/Layout')


// Icon component
import Icon from './components/Icon'
Vue.component('icon', Icon)

// Container component
import Container from './components/Container'
Vue.component('container', Container)

// ContentView component
import ContentView from './components/ContentView'
Vue.component('content-view', ContentView)

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
    router,
    render: h => h(Layout),
    created() {
        this.$bus.handleResize()
    }
});
