export default {
    data() {
        return {
            window: {
                width: 0,
                height: 0
            },
            currentYear: 2019,
            sliderOnLeft: false
        }
    },
    created() {
        window.addEventListener('resize', this.handleResize)
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    },
    computed: {
        mobileView() {
            return this.window.width < 768
        }
    },
    methods: {
        handleResize() {
            this.window.width = window.innerWidth
            this.window.height = window.innerHeight
        }
    }
}
