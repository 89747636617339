<template>
    <div>
        <img :src="src" :width="size" :height="size"/>
    </div>
</template>

<script>
    export default {
        name: "icon",
        props: {
            'img': {default: ''},
            'size': {default: 38}
        },
        computed: {
            src() {
                return "/images/" + this.img + ".png"
            }
        }
    }
</script>

<style scoped>

</style>